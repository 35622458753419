import React, { FC, memo } from 'react'
import { Box } from '@ucheba/ui/components/Box/touch'
import { useAuthGuard } from '../bll'

/** Финальный шаг */
const ApplicationFormFinal: FC = (props) => {
  const { children } = props

  useAuthGuard()

  return <Box spacing='small'>{children}</Box>
}

export { ApplicationFormFinal }
export default memo(ApplicationFormFinal)
