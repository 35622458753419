import { Formik } from 'formik'
import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import { clientProductsRequestsSelectors } from '@ucheba/store/client-products/requests'
import { Form } from '@ucheba/ui/components/Form/touch'
import { useRouter } from 'next/router'
import { EApplicationFormSteps, IApplicationFormProps } from '../types'
import { ApplicationFormRequestCode } from './ApplicationFormRequestCode'
import { ApplicationFormRequestApplication } from './ApplicationFormRequestApplication'
import { useApplicationFormRequestCore, useRegistrToEvents, useStepName } from '../bll'

/** Форма отправки запроса */
const ApplicationFormRequest: FC<IApplicationFormProps> = (props) => {
  const {
    count,
    productId,
    productType,
    funnel,
    requestTarget,
    subjectId,
    eventsId,
    referralCode,
    specialOfferCode,
    aboutParent,
    agreementText,
  } = props
  const router = useRouter()
  const { query } = router

  const apiErrors = useSelector(clientProductsRequestsSelectors.error)
  const stepName = useStepName()
  const { initialValues, onSubmit } = useApplicationFormRequestCore({
    count,
    productId,
    productType,
    funnel,
    requestTarget,
    subjectId,
    referralCode,
    specialOfferCode,
    aboutParent,
  })
  useRegistrToEvents(eventsId, requestTarget)

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      <Form apiErrors={apiErrors} fillByProfile={!(query.name && query.phone)}>
        {stepName === EApplicationFormSteps.code ? (
          <ApplicationFormRequestCode />
        ) : (
          <ApplicationFormRequestApplication
            aboutParent={aboutParent}
            agreementText={agreementText}
          />
        )}
      </Form>
    </Formik>
  )
}

export { ApplicationFormRequest }
export default memo(ApplicationFormRequest)
