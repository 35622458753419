import React, { FC, memo } from 'react'
import { Box } from '@ucheba/ui/components/Box/touch'
import { useSelector } from 'react-redux'
import { TextField } from '@ucheba/ui/components/TextField/touch'
import { EDataKeys } from '@ucheba/utils/types/dataKeys'
import { ErrorText } from '@ucheba/ui/components/ErrorText/touch'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import { Button } from '@ucheba/ui/components/Button/touch'
import { ErrorMessage, Field, Formik } from 'formik'
import { profileSelectors } from '@ucheba/store/profile'
import { Form } from '@ucheba/ui/components/Form/touch'
import styled from 'styled-components'
import { useApplicationFormParentCore, useAuthGuard } from '../bll'
import content from '../data.json'

const BoxSC = styled(Box)`
  padding-bottom: 0;
`

/** Форма отправки данных родителя */
const ApplicationFormParent: FC = () => {
  const apiErrors = useSelector(profileSelectors.error)
  const { initialValues, onSubmit, isLoading } = useApplicationFormParentCore()

  useAuthGuard()

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      <Form apiErrors={apiErrors} fill fillByProfile>
        <BoxSC color='white' spacing='medium'>
          <Field
            as={TextField}
            name={EDataKeys.parentName}
            label={content.parent.fields.name}
            required
          />

          <ErrorMessage component={ErrorText} name={EDataKeys.parentName} />

          <Spacing spacing='xlarge' />

          <Field
            as={TextField}
            name={EDataKeys.parentPhone}
            type='tel'
            label={content.parent.fields.phone}
            required
          />

          <ErrorMessage component={ErrorText} name={EDataKeys.parentPhone} />

          <Spacing spacing='xlarge' />

          <Button block type='submit' color='orange' progress={isLoading}>
            {content.parent.buttonText}
          </Button>
        </BoxSC>
      </Form>
    </Formik>
  )
}

export { ApplicationFormParent }
export default memo(ApplicationFormParent)
